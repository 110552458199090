<template>
  <HeroBg
    heroTitle="Amplify Your Employer Brand to Reach the <br class='hidden xl:inline-block' /> Right Talent"
    heroDesc="Craft an irresistible employer identity and attract Africa’s crème de la crème talent"
    heroBtnText="Get Started"
    heroBtnLink="/contact"
    heroImg="hero10.png"
  />

  <div class="flex justify-center bg-white">
    <div class="w-full px-[24px] md:px-0 md:w-[65vw]">
      <div class="py-8">
        <br />
        <h2 class="mb-4 text-center text-[20px] md:text-[32px] font-bold">Who's getting ahead with CareerBuddy?</h2>
        <ScrollingLogos :logos="companyLogos" />
      </div>
    </div>
  </div>

  <div class="bg-light-yellow">
    <div class="container mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="py-16 md:py-24">
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          <div class="relative rounded-2xl overflow-hidden">
            <img src="@/assets/images/choice.png" class="w-full h-full object-cover rounded-2xl" alt="CareerBuddy Team Meeting" />
          </div>

          <div class="space-y-6">
            <h1 class="text-[32px] md:text-[40px] lg:text-[48px] leading-[1.1] font-semibold text-brand-black">
              We are the first choice platform for African professionals looking for their next move
            </h1>
            <p class="text-sm md:text-[18px] leading-[1.6] text-gray-600">
              At CareerBuddy, we believe your brand is a narrative waiting to captivate the hearts of top-notch professionals across the diverse and
              dynamic African landscape. Our bespoke Employer Branding Service is your compass to navigating the talent-rich terrains of Africa,
              magnetizing the right minds that resonate with your brand's ethos.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-white">
    <div class="container mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="py-16 md:py-24">
        <div class="text-center max-w-3xl mx-auto mb-16">
          <h2 class="text-[20px] md:text-[32px] text-brand-black leading-[1.1] font-semibold mb-6">What do you Get?</h2>
          <div class="w-20 h-1 bg-brand-black mx-auto"></div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div
            v-for="feature in features"
            :key="feature.title"
            class="bg-white rounded-2xl p-8 shadow-sm hover:shadow-md transition-shadow duration-300 border border-gray-100"
          >
            <div class="bg-light-yellow rounded-xl p-4 inline-block mb-6">
              <img :src="feature.icon" class="w-8 h-8" :alt="feature.title + ' Icon'" />
            </div>
            <h3 class="font-bold text-[20px] md:text-[22px] mb-4 text-brand-black">{{ feature.title }}</h3>
            <p class="text-gray-600 leading-relaxed">{{ feature.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-light-yellow">
    <div class="container mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="py-16 md:py-24">
        <div class="text-center max-w-3xl mx-auto mb-16">
          <h2 class="text-[20px] md:text-[32px] text-brand-black leading-[1.1] font-semibold mb-6">Why Bother about Employer Branding?</h2>
          <div class="w-20 h-1 bg-brand-black mx-auto"></div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          <div
            v-for="benefit in benefits"
            :key="benefit.title"
            class="group bg-brand-black rounded-2xl p-8 transition-all duration-300 hover:scale-[1.02] hover:shadow-xl"
          >
            <div class="mb-6">
              <img :src="benefit.icon" class="w-12 h-12" :alt="benefit.title + ' Icon'" />
            </div>
            <h3 class="font-bold text-[18px] md:text-[20px] leading-tight mb-4 text-white">
              {{ benefit.title }}
            </h3>
            <p class="text-gray-300 leading-relaxed text-sm md:text-base">
              {{ benefit.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <AudienceBreakdown />

  <div class="bg-brand-black relative overflow-hidden">
    <div class="absolute inset-0 bg-[radial-gradient(circle_at_top,_var(--tw-gradient-stops))] from-yellow-400/5 to-transparent"></div>
    <div class="container relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="py-20 md:py-32 text-center">
        <div class="flex justify-center mb-8">
          <img :src="partnershipIcon" alt="Partnership Icon" class="w-16 h-16 animate-float" />
        </div>

        <h2 class="text-[20px] md:text-[32px] font-bold text-white mb-6">Ready to work together?</h2>

        <p class="text-base md:text-[18px] leading-[1.3] mb-12 text-white/70 max-w-4xl mx-auto">
          Partner with CareerBuddy today, and let's craft a narrative that propels your
          <span class="hidden md:inline"><br /></span>
          organization into the hearts and minds of Africa's most coveted professionals
        </p>

        <a
          href="/contact"
          class="inline-flex items-center px-8 md:px-16 py-4 text-center bg-light-yellow text-brand-black text-[15px] md:text-[18px] rounded-2xl font-semibold transition-all duration-300 hover:bg-yellow-400 hover:scale-105 hover:shadow-xl"
        >
          Start Hiring
          <svg class="w-5 h-5 ml-2" viewBox="0 0 20 20" fill="currentColor">
            <path
              fill-rule="evenodd"
              d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </a>
      </div>
    </div>
  </div>

  <CreateAccount />
</template>

<script setup>
import HeroBg from '@/components/partials/HeroBg.vue';
import CreateAccount from '@/components/partials/CreateAccount.vue';
import AudienceBreakdown from '@/components/common/AudienceBreakdown.vue';
import { useMeta } from '@/utils/meta';

import ScrollingLogos from '@/components/common/ScrollingLogos.vue';
import remotePassLogo from '../../assets/images/remotepassLogo.svg';
import piggyVestLogo from '../../assets/images/piggyvestLogo.svg';
import moniePointLogo from '../../assets/images/moniepointLogo.svg';
import flutterwaveLogo from '../../assets/images/flutterwaveLogo.svg';
import autocheckLogo from '../../assets/images/autochek.png';
import bambooLogo from '../../assets/images/bamboo.png';
import codematicLogo from '../../assets/images/codematic.png';
import sabiLogo from '../../assets/images/sabi.png';
import techcabal from '../../assets/images/techcabal.png';

const companyLogos = [
  { src: remotePassLogo, alt: 'RemotePass' },
  { src: piggyVestLogo, alt: 'PiggyVest' },
  { src: moniePointLogo, alt: 'MoniePoint' },
  { src: flutterwaveLogo, alt: 'Flutterwave' },
  { src: autocheckLogo, alt: 'Autocheck' },
  { src: bambooLogo, alt: 'Bamboo' },
  { src: codematicLogo, alt: 'Codematic' },
  { src: sabiLogo, alt: 'Sabi' },
  { src: techcabal, alt: 'TechCabal' },
];

import brandingStrategyIcon from '@/assets/icons/branding-strategy.svg';
import storytellingIcon from '@/assets/icons/storytelling.svg';
import visualContentIcon from '@/assets/icons/visual-content.svg';
import analyticsIcon from '@/assets/icons/analytics.svg';
import digitalPlatformsIcon from '@/assets/icons/digital-platforms.svg';

const features = [
  {
    icon: brandingStrategyIcon,
    title: 'Tailored Branding Strategy',
    description:
      'Our seasoned brand strategists delve into the unique narrative of your organization, crafting a bespoke employer branding strategy that resonates with the aspirations of top talents across Africa.',
  },
  {
    icon: storytellingIcon,
    title: 'Authentic Storytelling',
    description:
      'We unveil the authentic narrative of your brand, weaving a compelling story that speaks to the hearts of potential candidates, portraying your company as a beacon of opportunity and growth.',
  },
  {
    icon: visualContentIcon,
    title: 'Engaging Visual Content',
    description:
      'Our creative team conjures engaging visual content that encapsulates the spirit of your brand, creating a vivid imprint in the minds of your target talent pool.',
  },
  {
    icon: analyticsIcon,
    title: 'Insightful Analytics',
    description:
      "Leverage our cutting-edge digital platforms to interact with potential candidates, offering a glimpse into your organization's culture and values.",
  },
  {
    icon: digitalPlatformsIcon,
    title: 'Interactive Digital Platforms',
    description:
      'Measure the impact of your employer branding with insightful analytics, understanding how your brand resonates in the African talent market.',
  },
];

import talentIcon from '@/assets/icons/talent.svg';
import retentionIcon from '@/assets/icons/retention.svg';
import cultureIcon from '@/assets/icons/culture.svg';
import growthIcon from '@/assets/icons/growth.svg';

const benefits = [
  {
    icon: talentIcon,
    title: 'Attract Elite Talent',
    description: 'Elevate your brand to become a magnet for the finest professionals, eager to contribute their expertise to your vision',
  },
  {
    icon: retentionIcon,
    title: 'Improve Retention',
    description: 'Build a workplace culture that not only attracts but retains top talent, reducing turnover and fostering long-term commitment',
  },
  {
    icon: cultureIcon,
    title: 'Strengthen Culture',
    description: 'Create a vibrant workplace culture that resonates with your values, attracting professionals who share your vision',
  },
  {
    icon: growthIcon,
    title: 'Drive Growth',
    description: 'Transform your employer brand into a powerful asset that drives sustainable business growth and market leadership',
  },
];

import partnershipIcon from '@/assets/icons/partnership.svg';

useMeta();
</script>

<style scoped>
.animate-float {
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}
</style>
